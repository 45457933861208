<template>
    <div class="seleccionar-admins" :class="show ? 'activo':'inactivo'">
        <div class="row header mx-0 py-2">
            <div class="col-12 d-flex ">
                <i class="icon-arrow-left-circle f-25 mr-4 my-auto cr-pointer" @click="$emit('close')" />
                <p class="f-17 f-500 my-auto">Eliminar residentes ({{usersEliminar.length}})</p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12">
                <div class="contenedor-residentes overflow-auto scroll-none">
                    <usuarios-grupo 
                    ref="usersGrupo"
                    class="cr-pointer"
                    eliminar
                    @eliminar="setEliminados"
                    />
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-4 justify-content-center">
            <div class  ="col-10">
                <button class="bg-black border text-white br-4 py-2 f-14 f-300 w-100" @click="eliminarResidentesGrupo">
                    Eliminar ({{usersEliminar.length}})
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import Chats from '~/services/chats/chat-admin'
import {mapGetters} from 'vuex'
export default {
    components:{
        usuariosGrupo: () => import('./usersGrupo.vue'),
    },
    props:{
        show:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            usersEliminar:[]
        }
    },
    computed:{
        ...mapGetters({
            grupo:'chatsGrupos/grupoActivo'
        }),
    },
    watch:{
        show(value){
            if(value){
                this.usersEliminar = []
                this.$refs.usersGrupo.clear()
            }
        }
    },
    methods:{
        setEliminados(users = []){
            this.usersEliminar = users
        },
        async eliminarResidentesGrupo(){
            try {
                if(this.usersEliminar.length === 0){
                    this.notificacion('',`Por favor seleccione los residentes que desea eliminar`,'warning')
                    return
                }
                const params = {
                    usuarios: this.usersEliminar,
                }
                const {data} = await Chats.eliminarResidentesGrupo(this.grupo.id_grupo, params)
                this.$store.dispatch('chatsGrupos/getUsuariosGrupo',this.grupo.id_grupo)
                this.notificacion('',`Residentes removidos del grupo correctamente`,'success')
                this.$emit('close')

            } catch (error) {
                this.errorCatch(error)
            }
        }
       
    }
}
</script>
<style lang="scss" scoped>
.info-chat-grupo{
    position: relative;
    .wh-164px{
        max-width: 164px;
        min-width: 164px;
        max-height: 164px;
        min-height: 164px;
    }
    .img-grupo{
        @extend .wh-164px;
        border-radius: 50%;
        border: 2px solid #F5F5F5;
    }
    .contenedor-info{
        height: calc(100vh - 115px);
    }
    .div-divisor{
        height: 5px;
        background: #F5F5F5;
    }
    .icon-navigation{
        transform: rotate( 90deg );
    }
    .tipo-user{
        i.icon-buildings{
            &:before{
                margin-left: 0px;
            }
        }
        i.icon-account-outline{
            font-size: 25px;
        }
    }
    @keyframes slidein {
        from {
            width: 0%;
        }

        to {
            width: 100%;
        }
    }

    .editar-grupo, .agregar-residentes, .seleccionar-admins, .envio-mensajes{
        background: #fff;
        height: calc(100vh - 56px);
        position: absolute;
        top: 0;
        width: 103.9%;
        right: -393.77px;
        display: none;
        &.activo{
            transform: translateX(-379px);
            display: block !important;
            animation-duration: 0.35s;
            animation-name: slidein;
        }
        .header{
            background: #f1f1f1;
        }
        .contenedor-img-grupo{
            @extend .wh-164px;
            border-radius: 50%;
            border: 2px solid #F5F5F5;
            position:relative;
            .bg-hover{
                @extend .wh-164px;
                border-radius: 50%;
                background: #000;
                opacity: 0;
                position: absolute;
                transition: .35s;
            }
            .img-grupo{
                border: none !important;
            }
            &:hover{
                .bg-hover{
                    opacity: 0.7;
                    transition: .45s;

                }
            }
        }
        .contenedor-residentes{
            height: calc(100vh - 206px);
        }
        .contenedor-residentes-admins{
            height: calc(100vh - 135px);
        }
        .mas-opciones.opt{
            position: relative;
            .cantidad{
                position: absolute;
                color: white;
                background: #FF4D59;
                border-radius: 7px;
                font-size: 8px;
                top: -6px;
                right: -10px;
                font-weight: 500;
            }
        }
    }
}
</style>